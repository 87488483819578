module.exports = {
  siteTitle: 'HNW REALTY CORP.', // <title>
  manifestName: 'HNW',
  manifestShortName: 'HNW Realty', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'HNW REALTY',
  subHeading: 'Residential & Commercial Real Estate For High Net Worth Individuals & Family Offices',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://hnwrealty.com',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:info@hnwrealty.com',
    },
  ],
};
